<template>
<div class="page-content w-100">
<!-- start heading -->
   <div class="row"> 
  <div class="col-sm-12">
     <p class="text-center page-heading">Requests</p>
   </div>
   </div>
    <!-- end heading -->
<div class="row mt-2 ">
<div class="col-sm-12" style="margin-bottom:30px;">
 <div class="request-section">
      <!-- start accordion -->
<div class="accordion box-shadow" id="accordionRequest">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHome" aria-expanded="true" aria-controls="collapseHome">
        <strong>Home visit by a doctor</strong>
      </button>
    </h2>
    <div id="collapseHome" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionRequest">
      <div class="accordion-body p-4">
        <HomeIndex></HomeIndex>
      </div>
    </div>
  </div>
  <!-- end first -->
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseReference" aria-expanded="false" aria-controls="collapseReference">
       <strong>Reference check</strong>
      </button>
    </h2>
    <div id="collapseReference" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionRequest">
      <div class="accordion-body p-4">
        <ReferenceCheck></ReferenceCheck>
      </div>
    </div>
  </div>
  <!-- end second -->
   <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSearch" aria-expanded="false" aria-controls="collapseSearch">
       <strong>Search for RxIx clinic</strong>
      </button>
    </h2>
    <div id="collapseSearch" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionRequest">
      <div class="accordion-body p-4">
        <SearchClinic></SearchClinic>
      </div>
    </div>
  </div>
  <!-- end third -->
</div>
 <!-- end accordion -->
</div>  
<!-- end family -->
<div class="hr-border my-4"></div>
<div class="pending-section">
 <!-- start accordion -->
         <div class="accordion box-shadow" id="accordionPending">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button bg-light-orange text-dark border-orange-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePending" aria-expanded="true" aria-controls="collapsePending">
        <strong>Pending requests</strong>
      </button>
    </h2>
    <div id="collapsePending" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionPending">
      <div class="accordion-body p-4">
        <PendingRequest></PendingRequest>
      </div>
    </div>
  </div>
  <!-- end first -->
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed bg-light-orange text-dark border-orange-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapseArchived" aria-expanded="false" aria-controls="collapseArchived">
       <strong>Archived requests</strong>
      </button>
    </h2>
    <div id="collapseArchived" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionPending">
      <div class="accordion-body p-4">
        <ArchivedRequest></ArchivedRequest>  
      </div>
    </div>
  </div>
  <!-- end second -->
</div>

      <!-- end accordion -->
</div>
<!-- end share with me section -->

  </div>
  </div>
 </div>
</template>
<script lang='js' src='./request.js'></script>