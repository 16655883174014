<template>
   <div class="border-color p-4 mt-3" style="border-radius: 20px;">
      <h5 class="text-color user-detail-title">Select your family member</h5>
      <div class="row my-1">
         <div class="col-12">
            <b>
               <a class="nav-link text-color" style="cursor: pointer;" id="patient1-tab" data-toggle="pill new" @click="addPatient=false;pastPatientRecord=true;patient=1" role="tab" aria-controls="patnt-1" aria-selected="false">Patient 1</a>
               <a class="nav-link text-color" style="cursor: pointer;" id="patient2-tab" data-toggle="pill new" @click="addPatient=false;pastPatientRecord=true;patient=2" role="tab" aria-controls="patnt-2" aria-selected="false">Patient 2</a>
               <a class="nav-link text-color" style="cursor: pointer;" id="patient3-tab" data-toggle="pill new" @click="addPatient=false;pastPatientRecord=true;patient=3" role="tab" aria-controls="patnt-3" aria-selected="false">Patient 3</a>
            </b>
            <button type="button" @click="addPatient=true;pastPatientRecord=false" class="btn btn-blue-color text-white">Add new member</button>
         </div>
      </div>
      <div v-if="addPatient">
         <div class="row mt-2">
            <div class="col-12">
               <div class="form-group">
                     <label class="text-color">Name</label>
                     <input type="text" id="patient_name" name="patient_name" class="form-control"/>
               </div>
            </div>
         </div>
         <div class="row my-3">
            <div class="col-md-4 col-12">
               <label class="text-color">Age (in years)</label>
               <input type="text" id="patient_age" name="patient_age" class="form-control"/>
            </div>
            <div class="col-md-4 col-12">
               <label class="text-color">Gender</label>
               <select class="form-control" id="inputGroupSelect01">
                  <option value="1">Male</option>
                  <option value="1">Female</option>
               </select>
            </div>
            <div class="col-md-4 col-12">
               <label class="text-color" style="font-size:16px;">Relationship with user</label>
               <select class="form-control" id="relationshipWithUser">
                  <option value="1">Option 1</option>
               </select>
            </div>
         </div>
      </div>
   </div>
   <div v-if="pastPatientRecord">
      <div class="border-color p-3 mt-4" style="border-radius: 20px;">
         <h5 class="text-color housevisit-title">When and where</h5>
         <div class="row mt-3">
            <div class="col-12">
               <h5 class="text-color">Patient {{patient}}</h5>
            </div>
         </div>
         <div class="row my-3">
            <div class="col-4">
               <label class="text-color">Date required</label>
               <input type="date" class="form-control"/>
            </div>
            <div class="col-2">
            </div>
            <div class="col-4">
               <label class="text-color">Preferred time</label>
               <select class="form-control" id="preferredTime">
                  <option value="1">Option 1</option>
               </select>
            </div>
         </div>
         <div class="row my-3">
            <div class="col-4">
            <button type="button" @click="selectAddress=true;addAddress=false" class="btn btn-blue-color text-white">Select address</button> 
            </div>
         </div>
         <div class="row my-1" v-if="selectAddress">
            <div class="col-12">
               <b>
                  <a class="nav-link text-color" style="cursor: pointer;" id="patient1-tab" data-toggle="pill new" @click="addAddress=false" role="tab" aria-controls="patnt-1" aria-selected="false">Address 1</a>
                  <a class="nav-link text-color" style="cursor: pointer;" id="patient2-tab" data-toggle="pill new" @click="addAddress=false" role="tab" aria-controls="patnt-2" aria-selected="false">Address 2</a>
               </b>
               <button type="button" @click="addAddress=true" class="btn btn-blue-color text-white">Add new address</button>
            </div>
         </div>
         <div v-if="addAddress">
            <div class="row mt-2">
               <div class="col-12">
                  <div class="form-group">
                     <label class="text-color">Address where doctor is required</label>
                     <input type="text" id="patient_name" name="patient_name" class="form-control"/>
                  </div>
               </div>
               <div class="col-12 mt-2">
                  <div class="form-group">
                     <input type="text" id="patient_name" name="patient_name" class="form-control"/>
                  </div>
               </div>
            </div>
            <div class="row my-3">
               <div class="col-6">
                  <label class="text-color">Landmark</label>
                  <input type="text" id="patient_age" name="patient_age" class="form-control"/>
               </div>
               <div class="col-6">
                  <label class="text-color">Alternate contact number</label>
                  <input type="text" id="patient_age" name="patient_age" class="form-control"/>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="border-color mt-4">
      <div class="row">
         <div class="col-3">
            <div class="bg-color reference-text"><label>Reference<br>Check</label></div>
         </div>
         <div class="col-9">
            <label class="checkbox-inline"><input type="checkbox" class="me-2" name="practice[]" id="opt1" value="opt1">I wish to take a user reference check. If i am not happy with the feedback, I will request for an alternate doctor / cancel my request.</label>
            <label class="checkbox-inline"><input type="checkbox" class="me-2" name="practice[]" id="opt2" value="opt2">I DO NOT wish to take a user reference check</label>
         </div>
      </div>
   </div>
   <div class="row mt-3">
      <b>
         <label class="text-color justify-content-md-center">I agree to share my Rxix records with the doctor who will be assigned for my consultation. I authorize to provide the doctor access to my records.</label>
         <label class="text-color justify-content-md-center">I understand that I have control over the access to my records and i can share or revoke their access, whenever and to whomever i deem fit.</label>
      </b>
      <hr class="hr-border" />
   </div>

   <div class="row mt-3">
      <div class="col-9">
         <label class="text-color justify-content-md-center">The amount payable by you is Rs. XXX/-</label>
      </div>
      <div class="col-3">
         <button type="button" @click="addPatient=true;" class="btn btn-color text-white">Pay</button>
      </div>
   </div>
</template>
<script lang='js' src='./home-visit.js'></script>