<template>
   <div class="m-2">
    <div class="row mt-1">
        <div class="col-12">
            <a class="nav-link text-color" style="cursor: pointer;padding: 0.25rem 1rem;" id="patient1-tab" data-toggle="pill new" @click="archivedConsultation=false;archivedrequestStatus=true;archivedPatient=1" role="tab" aria-controls="patnt-1" aria-selected="false">Patient 1</a>
            <a class="nav-link text-color" style="cursor: pointer;padding: 0.25rem 1rem;" id="patient2-tab" data-toggle="pill new" @click="archivedConsultation=false;archivedrequestStatus=true;archivedPatient=2" role="tab" aria-controls="patnt-2" aria-selected="false">Patient 2</a>
            <a class="nav-link text-color" style="cursor: pointer;padding: 0.25rem 1rem;" id="patient3-tab" data-toggle="pill new" @click="archivedConsultation=false;archivedrequestStatus=true;archivedPatient=3" role="tab" aria-controls="patnt-3" aria-selected="false">Patient 3</a>
        </div>
    </div>
    <hr class="hr-border"/>
    <div  v-if="archivedrequestStatus==true">
        <div class="row mt-2">
            <div class="col-sm-12">
            <h4 class="text-color">Patient {{archivedPatient}}</h4>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-8">
                <div class="row">
                    <div class="col-md-4 col-12">
                    <a class="nav-link bg-color text-white text-center cursor-pointer my-1" id="date1-tab" data-toggle="pill new" @click="archivedConsultation=true;reqDate=1" role="tab" aria-controls="date-1" aria-selected="false">Request Date 1</a>
                    </div>
                    <div class="col-md-4 col-12">
                    <a class="nav-link bg-color text-white text-center cursor-pointer my-1" id="date1-tab" data-toggle="pill new" @click="archivedConsultation=true;reqDate=3" role="tab" aria-controls="date-1" aria-selected="false">Request Date 3</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-12">
                    <a class="nav-link bg-color text-white text-center cursor-pointer my-1" id="date2-tab" data-toggle="pill new" @click="archivedConsultation=true;reqDate=2" role="tab" aria-controls="date-2" aria-selected="false">Request Date 2</a>
                    </div>
                    <div class="col-md-4 col-12">
                    <a class="nav-link bg-color text-white text-center cursor-pointer my-1" id="date1-tab" data-toggle="pill new" @click="archivedConsultation=true;reqDate=4" role="tab" aria-controls="date-1" aria-selected="false">Request Date 4</a>
                    </div>
                </div>
            </div>
        </div>
        <hr class="hr-border"/>
    </div>
        <div  v-if="archivedConsultation==true">
            <div class="row mt-3">
                <div class="col-md-12 col-12">
                    <h4 class="text-color"> Request Date {{reqDate}}</h4>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-12">
                <p class="text-color">Consultation by Dr. Doctor name</p>
                </div>
            </div>
            <div class="row mb-5">
               <div class="col-md-12 col-12">
                   <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                       <div class="carousel-inner">
                           <div class="carousel-item active">
                               <p>Case sheet details for date {{reqDate}} </p>
                           </div>
                       </div>
                   </div>
               </div>
            </div>

             <div class="row mt-2">
                 <div class="col-sm-12">
                 <p class="text-color">Prescription</p>
                 </div>
            </div>
            <div class="row mb-5">
               <div class="col-md-12 col-12">
                   <div id="CaseSheet" class="carousel slide" data-ride="carousel">
                       <div class="carousel-inner">
                           <div class="carousel-item active">
                               <img class="mx-auto d-block w-25" src="~@/assets/img/images/Health-record.png" alt="Prescription">
                           </div>
                       </div>
                   </div>
               </div>
             </div>

        </div>
    </div>
</template>
<script lang='js' src='./archived-requests.js'></script>