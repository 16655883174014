<template>
   <div class="row mt-3">
      <div class="col-4"></div>
      <div class="col-4">
         <div class="form-group">
               <select class="form-control" id="inputGroupSelect01">
                  <option value="0">Select City</option>
               </select>
         </div>
      </div>
      <hr class="hr-border mt-2" />
   </div>

   <div class="row mt-2" v-for="index in 3" :key="index">
      <div class="col-12">
         <h5 class="text-color">Name of the clinic {{index}}</h5>
      </div>
      <hr class="hr-border mt-2" />
   </div>
</template>
<script lang='js' src='./search-clinic.js'></script>