import HomeIndex from './home-visit/home-visit.vue'
import ReferenceCheck from './reference-check/reference-check.vue'
import SearchClinic from './search-clinic/search-clinic.vue'
import PendingRequest from './pending-requests/pending-requests.vue'
import ArchivedRequest from './archived-requests/archived-requests.vue'
export default {

  name: 'app',
  components: {
    HomeIndex,
    ReferenceCheck,
    SearchClinic,
    PendingRequest,
    ArchivedRequest,
  },


}