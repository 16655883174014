export default{
    data(){
        return{
            addPatient:false,
            pastPatientRecord:false,
            selectAddress:false,
            addAddress:false,
            patient:0,
        }
    }
}