<template>
   <div class="m-2">
            <div class="row">
                <div class="col-8">
                    <div class="row">
                     <div class="col-md-4 col-12">
                        <a class="nav-link bg-color text-white text-center cursor-pointer my-1" id="date1-tab" data-toggle="pill new" @click="requestStatus=true;req=1" role="tab" aria-controls="date-1" aria-selected="false">Request no. 1</a>
                     </div>
                    </div>
                    <div class="row">
                     <div class="col-md-4 col-12">
                        <a class="nav-link bg-color text-white text-center cursor-pointer my-1" id="date2-tab" data-toggle="pill new" @click="requestStatus=true;req=2" role="tab" aria-controls="date-2" aria-selected="false">Request no. 2</a>
                     </div>
                    </div>
                </div>
            </div>
        
    <hr class="hr-border"/>
        <div  v-if="requestStatus==true">
            <div class="row mt-2">
                <div class="col-sm-12">
                <p class="text-color">Status of request no. {{req}}</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-12">
                <p class="text-color" style="margin-left: 30px;">Requested on 01-Mar-2022 at 04:23 PM</p>
                <p class="text-color" style="margin-left: 30px;">Assigned Dr. Doctor name on 01-Mar-2022 at 06:00 PM</p>
                <p class="text-color" style="margin-left: 30px;">Consultation scheduled for 02-Mar-2022 at 02:00 PM</p>
                </div>
            </div>

        </div>
      </div>
</template>
<script lang='js' src='./pending-requests.js'></script>